<template>
    <div class="certificateServices">
        <!-- 头部组件 -->
        <headPage />
        <!-- 顶部图片 -->
        <div class="top-img" ></div>
        <div class="block-item1">
            <div class="block-item1-wrap">
                <div class="left">
                    <div class="title">
                        咨询服务
                    </div>
                    <div class="detail">
                        提供包括劳动合同、员工手册、规章制度保密协议、竞业禁止、培训协议等合规审核，工伤赔偿、工伤纠纷代办、劳动争议律师出庭等专属法律问题咨询服务，提升企业人力资源管理工作效率，助力企业构建人力管理战略规划。
                    </div>
                </div>
                <div class="right">
                    
                </div>
            </div>
        </div>
        <div class="block-item2">
            <div class="block-item2-wrap">
                <div class="left">
                    
                </div>
                <div class="right">
                    <div class="title">
                       人才招聘
                    </div>
                    <div class="detail">
                     依托现有的职业人群的培训， 帮助企业在职业技能人群的线上招聘服务、rpo代理招聘、岗位外包等服务的对接，以及高端人群的猎头服务。
                    </div>
                </div>
            </div>
        </div>
        <div class="block-item3">
            <div class="block-item3-wrap">
                <div class="left">
                    <div class="title">
                       企业内训
                    </div>
                    <div class="detail">
                        汇集海量优质课程与自主研发的数字化平台，为企业提供更实用、更系统、更高效的人才教育解决方案，助力企业综合竞争力与业绩提升。
                    </div>
                </div>
                <div class="right">
                    
                </div>
            </div>
        </div>
        <div class="block-item4">
            <div class="block-item4-wrap">
                <div class="left">
                    
                </div>
                <div class="right">
                    <div class="title">
                        助残就业
                    </div>
                    <div class="detail">
                        基于平台自有资源，打造 “培训-就业-关爱” 的一体化综合服务解决方案。 帮助更多求职者提升职业技能，匹配适宜岗位，获得美好生活。
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-img">
            <h4 >安知——您的智慧人力管家</h4>
            <div class="btn"> <el-button  class="bgc-jg" style="width:150px;"  @click="ConsultNow">立即咨询</el-button></div>
           
        </div>
        <!-- 底部组件 -->
        <bottomPage />
        <!-- 对话框 -->
        <el-dialog
            title=""
            class="popUp"
            width="600px"
            top="10%"
            :modal="false"
            :visible.sync="dialogVisible"
            @closed="dialogClosed"
            >
            <div class="contactTeacherPopup">
                <div class="dialogTop">
                <img src="@/assets/mukeCooperation/icon15.png" alt="" />
                    <span>立即咨询</span>
                </div>
                <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="公司全称" prop="resourceName">
                        <el-input @focus="clearValidate('resourceName')" placeholder="以营业执照为准" maxlength="50" show-word-limit v-model="ruleForm.resourceName"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="resourceFullName">
                        <el-input @focus="clearValidate('resourceFullName')" placeholder="请输入姓名" v-model="ruleForm.resourceFullName"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="resourceMobile">
                        <el-input @focus="clearValidate('resourceMobile')" placeholder="请输入手机号" v-model="ruleForm.resourceMobile" @input="filter('resourceMobile')"></el-input>
                    </el-form-item>
                    <div class="flexcc" style="margin-top:40px;">
                        <el-button class="bgc-bv" style="width:180px;" @click="submit">提 交</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import headPage from "../../wrongAuthenticatePage/headPage.vue";
    import bottomPage from "../../wrongAuthenticatePage/bottomPage.vue";
    export default {
        name:"certificateServices",
        components:{
            headPage,
            bottomPage
        },
        data(){
            return{
                // 对话框可见性
                dialogVisible:false,
                // 表单数据
                ruleForm:{
                    resourceName:"", // 公司全称
                    resourceMobile:"", // 姓名
                     resourceFullName:"",// 联系人姓名
                },
                // 表单规则
                rules:{
                    resourceName:[
                        {required:true,message:"请填写公司全称",trigger:"blur"}
                    ],
                    resourceFullName:[
                        {required:true,message:"请填写姓名",trigger:"blur"}
                    ],
                    resourceMobile:[
                        {required: true,validator: this.$validatePhone,trigger:"blur"}
                    ]
                }
            }
        },
        methods:{
            // 过滤非法字符
            filter(key){
                const {ruleForm}=this;
                ruleForm[key]=ruleForm[key].replace(/[^\d]/,"");
            },
            //立即咨询
            ConsultNow() {
                this.dialogVisible = true;
            },
            // 清除校验结果
            clearValidate(key){
                this.$refs.ruleForm.clearValidate(key);
            },
            // 对话框关闭以后重置表单
            dialogClosed(){
                this.$refs.ruleForm.resetFields();
                this.ruleForm={
                     resourceName:"", // 公司全称
                    resourceFullName:"", // 姓名
                    resourceMobile:"" // 手机号
                }
            },
            // 提交
            submit(){
                const {ruleForm}=this;
                let flag=true;
                Object.keys(ruleForm).forEach(key=>{
                    if(ruleForm[key].trim()==="") return flag=false;
                });
                if(!flag) return this.$message.info("请先完善信息再提交!");
                this.$refs.ruleForm.validate(valid=>{
                    if(valid){
                        this.$post("/biz/human/resources/insert",ruleForm).then(()=>{
                            this.$message.success("提交成功，请等待平台审核");
                            this.dialogVisible=false;
                        });
                    }else{
                        return false;
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    .certificateServices{
        height: 100vh;
        overflow-y: scroll;
        .top-img{
            height: 24.6875rem;
            background-image: url("../../../assets/loginIndex/humanTopBg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
        .block-item1{
            height: 32.8125rem;
            background-color: white;
            .block-item1-wrap{
                width: 73rem;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                .left{
                    padding-left: 1.0625rem;
                    .title{
                        font-size: 1.4375rem;
                        font-weight: bold;
                        color: #333333;
                        margin-bottom: .625rem;
                         text-align:left
                    }
                    .detail{
                        font-size: .9375rem;
                        font-weight: 400;
                        color: #666666;
                        width: 21.5625rem;
                         text-align:left;
                         line-height:1.4375rem;
                    }
                }
                .right{
                    height: 19.0625rem;
                    width: 29.4375rem;
                    background-image: url("../../../assets/loginIndex/humanImg1.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
        .block-item2{
            height: 32.8125rem;
            background-color: #F9F9F9;
            .block-item2-wrap{
                width: 73rem;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                .right{
                    text-align: right;
                    padding-right: 1.0625rem;
                    .title{
                        font-size: 1.4375rem;
                        font-weight: bold;
                        color: #333333;
                        margin-bottom: .625rem;
                         text-align:left
                    }
                    .detail{
                        font-size: .9375rem;
                        font-weight: 400;
                        color: #666666;
                        width: 21.5625rem;
                         text-align:left;
                          line-height:1.4375rem;
                    }
                }
                .left{
                   height: 19.0625rem;
                    width: 29.4375rem;
                    background-image: url("../../../assets/loginIndex/humanImg2.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
        .block-item3{
            height: 32.8125rem;
            background-color: white;
            .block-item3-wrap{
                width: 73rem;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                .left{
                    padding-left: 1.0625rem;
                    .title{
                        font-size: 1.4375rem;
                        font-weight: bold;
                        color: #333333;
                        margin-bottom: .625rem;
                         text-align:left
                    }
                    .detail{
                        font-size: .9375rem;
                        font-weight: 400;
                        color: #666666;
                        width: 21.5625rem;
                         text-align:left;
                          line-height:1.4375rem;
                    }
                }
                .right{
                   height: 19.0625rem;
                    width: 29.4375rem;
                    background-image: url("../../../assets/loginIndex/humanImg3.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
        .block-item4{
            height: 32.8125rem;
            background-color: #F9F9F9;
            .block-item4-wrap{
                width: 73rem;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                .right{
                    text-align: right;
                    padding-right: 1.0625rem;
                    .title{
                        font-size: 1.4375rem;
                        font-weight: bold;
                        color: #333333;
                        margin-bottom: .625rem;
                         text-align:left
                    }
                    .detail{
                        font-size: .9375rem;
                        font-weight: 400;
                        color: #666666;
                        width: 21.5625rem;
                        text-align:left;
                         line-height:1.4375rem;
                    }
                }
                .left{
                    height: 19.0625rem;
                    width: 29.4375rem;
                    background-image: url("../../../assets/loginIndex/humanImg4.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
        .bottom-img {
            height: 23.4375rem;
             background-image: url("../../../assets/loginIndex/humanBottomBg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
           h4 {
                color:#fff;
                text-align: center;
                padding-top:7.5rem;
                font-size: 2.8125rem;
            }
            .btn {
                padding-top:2.8125rem;
                display: flex;
                    justify-content: center;
            }
        }
        .dialog-footer{
            .el-button:nth-child(2){
                background: #2B77FD;
            }
        }
    }
    .certificateServices {
        /deep/.el-dialog__wrapper {
            z-index: 9999 !important;
        }
        /deep/.el-dialog {
            background-color: transparent;
            border-radius: 20px;
        }
        /deep/.el-dialog__header {
            background: #fff;
            border-top-left-radius: 20px !important;
            border-top-right-radius: 20px !important;
            padding: 20px 20px 10px;
            .el-dialog__title {
                color: #333;
                font-size: 20px;
            }
            .el-dialog__headerbtn {
                top: 38px;
                .el-dialog__close {
                    color: #333;
                }
            }
            .el-dialog__headerbtn:focus .el-dialog__close,
            .el-dialog__headerbtn:hover .el-dialog__close {
                color: #333;
            }
        }
        /deep/.el-dialog__body {
            background: #fff;
            border-bottom-left-radius: 20px !important;
            border-bottom-right-radius: 20px !important;
            padding: 0 30px;
        }
        .contactTeacherPopup {
            padding-bottom: 57px;
            .dialogTop {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 45px;
                span {
                    font-size: 23px;
                    color: #333;
                }
            }
        }
    }
</style>